.swal2-container {
  z-index: 10000 !important;
}

.makeStyles-appContent-11 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.jss11 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
